import preset from '@/@core/preset/preset'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework';

import sunny from './custom-icons/sunny.vue'
import logout from './custom-icons/logout.vue';
import home from './custom-icons/home.vue';
import menu from './custom-icons/menu.vue';
import card from './custom-icons/card.vue';

Vue.use(Vuetify)

export default new Vuetify({
    preset,
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        options: {
            customProperties: true,
            variations: false,
        },
    },
    icons: {
        values: {
            sunny: { // name of our custom icon
                component: sunny, // our custom component
            },
            logout: { // name of our custom icon
                component: logout, // our custom component
            },
            home: { // name of our custom icon
                component: home, // our custom component
            },
            menu: { // name of our custom icon
                component: menu, // our custom component
            },
            card: { // name of our custom icon
                component: card, // our custom component
            },
        },
    },
})
