<template>
    <!-- <div @mousemove="mouseMove"> -->
    <component :is="resolveLayoutVariant"
               :class="`skin-variant--${appSkinVariant}`">
        <!-- <transition :name="appRouteTransition"
                    mode="out-in"
                    appear> -->
        <div>
            <loader :visible="loading"></loader>
            <router-view></router-view>
        </div>

        <!-- </transition> -->
    </component>
    <!-- </div> -->
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import useDynamicVh from '@core/utils/useDynamicVh'

import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue';
import LayoutContentCenturion from '@/layouts/variants/content/centurion/LayoutContentCenturion.vue';

import sharedServices from './services/shared-services';
import store from './store'
import { mapState } from 'vuex';
import loader from './views/loader.vue'

import Encrypted from '@/layouts/variants/encrypted/Encrypted.vue'

import { getVuetify } from '@core/utils'

// import router from './router';
// Dynamic vh

export default {
    components: {
        LayoutContentVerticalNav,
        LayoutBlank,
        loader,
        Encrypted,
        LayoutContentCenturion
    },
    computed: {
        ...mapState("loader", ["loading"])
    },
    watch: {
        $route(to, from)
        {
            window.scrollTo(0, 0);
        }
    },
    setup()
    {
        let identitySeed = sharedServices.getIdSeed();
        store.set('requestKeys/identitySeed', identitySeed);

        const { route } = useRouter()
        const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

        const $vuetify = getVuetify()

        const { handleBreakpointLayoutSwitch } = useLayout()
        handleBreakpointLayoutSwitch()

        const resolveLayoutVariant = computed(() =>
        {
            let lang = localStorage.getItem('language');
            if (lang == 'en')
            {
                $vuetify.rtl = false;
            }
            else if (lang == 'ar')
            {
                $vuetify.rtl = true;
            }
            let encrypted = localStorage.getItem('Encrypted');
            if (encrypted == 'demo@amex')
            {
                if (route.value.meta.layout === 'blank') return 'layout-blank'
                if (route.value.meta.layout === 'content') 
                {
                    return `layout-content-${appContentLayoutNav.value}-nav`
                }
                if (route.value.meta.layout === 'centurion') 
                {
                    return `layout-content-centurion`
                }
            }
            else
            {
                return 'encrypted'
            }

            return null
        })

        useDynamicVh()

        return {
            resolveLayoutVariant,
            appSkinVariant,
            appRouteTransition,
        }
    },
}
</script>
